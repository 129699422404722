<template>
  <div>
    <b-card>
      <b-card-header>
        <div>
          <b-card-title class="mb-1">{{lunes}} - {{domingo}}</b-card-title>
          <b-card-sub-title>Primary Metric</b-card-sub-title>
        </div>
        <div class="d-flex align-items-center">
          <feather-icon icon="CalendarIcon" size="16" />
          <flat-pickr
            v-model="rangePicker"
            :config="{ mode: 'range' }"
            class="form-control flat-picker bg-transparent border-0 shadow-none"
            placeholder="Select Another Week"
          />
        </div>
      </b-card-header>
      <hr />
      <b-row>
        <b-col cols="12">
          <label class="font-weight-bolder">Choose your primary metric</label>
          <b-list-group flush v-for="value in primarymetric" :key="value">
            <b-list-group-item
              @click="metricSelection(value)"
              v-bind:class="{ active: selecionado == value }"
              >{{ value }}</b-list-group-item
            >
          </b-list-group>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col lg="6" md="12" sm="12" cols="12">
          <div>
            <label class="font-weight-bolder"
              >Biggest Obstacle this Week?</label
            >
            <b-form-textarea v-model="area1" rows="5" />
          </div>
        </b-col>
        <b-col lg="6" md="12" sm="12" cols="12">
          <div>
            <label class="font-weight-bolder"
              >What do you need to do to get over this obstacle?</label
            >
            <b-form-textarea v-model="area2" rows="5" />
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col lg="6" md="12" sm="12" cols="12">
          <div>
            <label class="font-weight-bolder"
              >On a scale of 1-10, what is your morale?</label
            >
            <b-form-rating
              no-border
              v-model="value"
              variant="success"
              stars="10"
              size="lg"
            />
          </div>
        </b-col>
        <b-col lg="6" md="12" sm="12" cols="12">
          <label class="font-weight-bolder"
            >What are your top 1-3 goals for this week?</label
          >
          <b-input-group>
            <b-form-input v-model="input1" />
            <b-input-group-append>
              <b-button variant="outline-primary" @click="clearInput(1)">
                <feather-icon icon="XIcon" />
              </b-button>
            </b-input-group-append>
          </b-input-group>

          <b-input-group class="mt-1">
            <b-form-input v-model="input2" />
            <b-input-group-append>
              <b-button variant="outline-primary" @click="clearInput(2)">
                <feather-icon icon="XIcon" />
              </b-button>
            </b-input-group-append>
          </b-input-group>

          <b-input-group class="mt-1">
            <b-form-input v-model="input3" />
            <b-input-group-append>
              <b-button variant="outline-primary" @click="clearInput(3)">
                <feather-icon icon="XIcon" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <hr />
      <div class="text-right mt-3">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="insertPrimaryMetric"
        >
          Save Goals
        </b-button>
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BListGroup,
  BListGroupItem,
  BRow,
  BCol,
  BFormTextarea,
  BFormRating,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroupPrepend,
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import SellerService from "@/services/SellerService";
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import 'moment-timezone';
const zone=Intl.DateTimeFormat().resolvedOptions().timeZone
export default {
  components: {
    BCard,
    BCardTitle,
    BCardSubTitle,
    BCardHeader,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BFormTextarea,
    BFormRating,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroupPrepend,
    BButton,
    flatPickr
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      primarymetric: [
        "Looking for a Web-3 Job",
        "Build my own start-up",
        "Building my NFT portafolio",
        "Building my crypto portafolio",
      ],
      area1: "",
      area2: "",
      value: null,
      input1: "",
      input2: "",
      input3: "",
      selecionado: "",
      rangePicker: [],
      lunes:null,
      lunesinsert:null,
      domingo:null,
      domingoinsert:null,
    };
  },
  mounted() {
    this.lunes=this.getLunes()
    this.domingo=this.getDomingo()
   this.lunesinsert=this.getLunesInsert()
   this.domingoinsert=this.getDomingoInsert()
   this.getGoals()
  },
  methods: {
    insertPrimaryMetric: async function () {
      var goals = [];
      var i=null
      const vm=this
      if (
        this.selecionado != "" &&
        this.area1 != "" &&
        this.area2 != "" &&
        this.value != null
      ) {
        if (this.input1 == "" && this.input2 == "" && this.input3 == "") {
          this.$swal({
            title: "Warning",
            text: "Insert one goal",
            icon: "warning",
            showClass: {
              popup: "animate__animated animate__fadeIn",
            },
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        } else {
          if (this.input1 != "" && this.input1!=null) {
            goals.push({name:this.input1,value:false});
          }
          if (this.input2 != "" && this.input2!=null) {
            goals.push({name:this.input2,value:false});
          }
          if (this.input3 != "" && this.input3!=null) {
            goals.push({name:this.input3,value:false});
          }

          if (this.rangePicker.length<=0) {
              i = await SellerService.insertPMElite(
            JSON.parse(localStorage.getItem("auth")).discordId,
            this.lunesinsert,this.domingoinsert,
            this.selecionado,
            this.area1,
            this.area2,
            this.value,
            goals
          );
        }
        else{
    
          var range=this.rangePicker.split(' to ')
           var dstart=moment(range[0]).tz(zone, true).toISOString(true)
        var dend=moment(range[1]).tz(zone, true).toISOString(true)
          i = await SellerService.insertPMElite(
            JSON.parse(localStorage.getItem("auth")).discordId,
            dstart,dend,
            this.selecionado,
            this.area1,
            this.area2,
            this.value,
            goals
          );
        }

          this.$swal({
            title: "Save Goals Successfull",
            text: "Go back Home",
            icon: "success",
             allowOutsideClick: false,
            showClass: {
              popup: "animate__animated animate__fadeIn",
            },
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          }).then(result => {
        if (result.value) {
                vm.$router.go(-1)
         }
      });
        }
      } else {
        this.$swal({
          title: "We have a problem",
          text: "Insert all questions",
          icon: "error",
          showClass: {
            popup: "animate__animated animate__fadeIn",
          },
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }
    },
    getGoals:async function(){
      const g=(await SellerService.getGoals(JSON.parse(localStorage.getItem("auth")).discordId)).data
      this.selecionado=g.primaryMetric
      this.area1=g.biggestObstacle
      this.area2=g.whatYouNeed
      this.value=g.moraleScale
      if (g.topGoals[0]) {
           this.input1=g.topGoals[0].name
      }
      if (g.topGoals[1]) {
        this.input2=g.topGoals[1].name
      }
      if (g.topGoals[2]) {
        this.input3=g.topGoals[2].name 
      }
    },
    clearInput: function (p) {
      if (p == 1) {
        this.input1 = "";
      }
      if (p == 2) {
        this.input2 = "";
      }
      if (p == 3) {
        this.input3 = "";
      }
    },
    metricSelection: function (p) {
      this.selecionado = p;
    },
      getLunes:function() {
     var d = new Date();
      var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6:1); 
        var ff= new Date(d.setDate(diff));
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
       return monthNames[ff.getMonth()]+' '+ff.getDate()+', '+ff.getFullYear()
    },
  getDomingo:function() {
  const today = new Date();
  const first = today.getDate() - today.getDay() + 1;
  const last = first + 6;
  const sunday = new Date(today.setDate(last));
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
   return monthNames[sunday.getMonth()]+' '+sunday.getDate()+', '+sunday.getFullYear()
    },
     getLunesInsert:function() {
     var d = new Date();
      var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6:1); 
        var ff= new Date(d.setDate(diff));
       return ff.toISOString()
    },
      getDomingoInsert:function() {
  const today = new Date();
  const first = today.getDate() - today.getDay() + 1;
  const last = first + 6;
  const sunday = new Date(today.setDate(last));
   return sunday.toISOString()
    }
  },
  
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>